<template>
  <div class="home__top-banner">
    <div class="hero-banner">
      <BaseImg
        relative-src="/assets/images/cheat/hero-banner-pc.webp"
        alt="hero-banner"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/hero-banner/index.scss"></style>
